import React from 'react'
import SEO from 'components/seo'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import MemberSVG, { getMemberImageSrc } from 'components/MemberSVG'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faLinkedin,
  faGithub
} from '@fortawesome/free-brands-svg-icons'
import { isMobile } from 'react-device-detect'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { composeClasses, jsonId } from 'utils'
import LocationIcon from '../images/locationIcon.svg'
import shareIcon from '../images/shareIcon.svg'
import ShareLarge from 'components/shareLarge'
import parse from 'html-react-parser'

import * as BlogStyles from 'styles/blog.module.css'
import * as styles from '../styles/blogDetail.module.css'
import * as MemberStyles from '../styles/member.module.css'
import * as sharedStyles from '../styles/sharedLayout.module.css'
import * as SocialMediaStyles from '../styles/shareSmall.module.css'
import Accordion from 'components/accordion'
import HelmetExport from 'react-helmet'

export const query = graphql`
  query($id: ID!, $first: Int) {
    wordPress {
      team(id: $id) {
          membersFieldGroup {
            gcpCertification {
              gcpCertificationTitle
                gcpCertificationBadge {
                  sourceUrl
              }
            }
            awsCertification {
              awsCertificationBadge {
                sourceUrl
              }
              awsCertificationTitle
            }
            memberCertification {
              certificationBadge {
                sourceUrl
              }
              certificationTitle
            }
            linuxFoundationCertification {
              linuxFoundationCertificationBadge {
                sourceUrl
              }
              linuxFoundationCertificationTitle
            }
            memberAffiliations {
              affiliations
            }
            memberFirstName
            memberSurname
            memberJobTitle
            memberCity
            memberCountry
            memberDescription
            memberGithubUrl
            memberTwitterUrl
            memberLinkedinUrl
            memberGender
            memberCoreEngineeringSkills {
              coreEngineeringSkills
            }
            memberSpecialisations {
              specialisations
            }
            microsoftCertification {
              microsoftCertificationTitle
              microsoftCertificationBadge {
                sourceUrl
            }
          }
        }
        slug
      }
      posts(first: $first) {
        edges {
          node {
            date
            slug
            title
            author {
              node {
                name
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
const Members = ({ data, pageContext }) => {
  const {
    memberFirstName,
    memberSurname,
    memberDescription,
    memberJobTitle,
    memberCity,
    memberCountry,
    memberGithubUrl,
    memberTwitterUrl,
    memberLinkedinUrl,
    memberGender,
    memberCoreEngineeringSkills,
    memberSpecialisations,
    microsoftCertification,
    awsCertification,
    linuxFoundationCertification,
    memberAffiliations,
    memberCertification,
    gcpCertification
  } = data.wordPress.team.membersFieldGroup

  const memberPost = data.wordPress.posts.edges
  const siteUrl = data.site && data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext
  const memberSlug = data.wordPress.team.slug
  const url = typeof window !== 'undefined' ? window.location.href : `https://deimos.io/member/${memberSlug}`

  let blogAuthor = []
  memberPost && memberPost.map((item) => {
    blogAuthor.push(item.node.author.node.name)
  })
  const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0)
  const numberOfOccurence = countOccurrences(blogAuthor, `${memberFirstName} ${memberSurname}`)

  const openModal = () => {
    const modal = document.getElementById('shareModal')
    modal.style.display = 'block'
    window.onclick = function (event) {
      if (event.target === modal) {
        modal.style.display = 'none'
      }
    }
  }

  const socialMediaDisplay = () => {
    return (
      <div className={MemberStyles.socialMedia}>
        {memberTwitterUrl && (
          <div className={MemberStyles.socialMediaLinksContainer}>
            <span>
              <a
                className={SocialMediaStyles.button}
                href={memberTwitterUrl}
                target='_blank'>
                <FontAwesomeIcon
                  icon={faTwitter}
                  size='1x' />
              </a>
            </span>
          </div>
        )}
        {memberLinkedinUrl && (
          <div className={MemberStyles.socialMediaLinksContainer}>
            <span>
              <a
                className={SocialMediaStyles.button}
                href={memberLinkedinUrl}
                target='_blank'>
                <FontAwesomeIcon
                  icon={faLinkedin}
                  size='1x' />
              </a>
            </span>
          </div>
        )}
        {memberGithubUrl && (
          <div className={MemberStyles.socialMediaLinksContainer}>
            <span>
              <a
                className={SocialMediaStyles.button}
                href={memberGithubUrl}
                target='_blank'>
                <FontAwesomeIcon
                  icon={faGithub}
                  size='1x' />
              </a>
            </span>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/member`,
            'name': 'The Team',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${memberFirstName} ${memberSurname}`,
            'position': 3
          }]
        })},
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'Person',
          'address': {
            '@type': 'PostalAddress',
            'addressLocality': 'Cape Town',
            'addressRegion': 'WC',
            'postalCode': '7925',
            'streetAddress': '50 Canterbury Street, Zonnebloem'
          },
          'birthDate': '',
          'birthPlace': '',
          'colleague': [],
          'email': 'info@deimos.io',
          'gender': `'${memberGender}'`,
          'image': `'${typeof window !== 'undefined' ? window.location.origin : ''}${getMemberImageSrc(memberGender, true)}'`,
          'jobTitle': `'${memberJobTitle}'`,
          'name': `'${memberFirstName} ${memberSurname}'`,
          'nationality': `'${memberCountry}'`,
          'sameAs': [
            `'${memberLinkedinUrl}'`
          ],
          'url': `'${typeof window !== 'undefined' ? window.location.href : ''}'`
        })
        }
      </HelmetExport>
      <SEO
        description={memberDescription}
        title={`${memberFirstName} ${memberSurname} - ${memberJobTitle}`}
      />
      <div className={MemberStyles.wrapper}>
        <div className={composeClasses(sharedStyles.verticalContainer, styles.breadCrumbsSection)}>
          <div className={composeClasses(BlogStyles.verticalContainerInner, MemberStyles.verticalBlogInner)}>
            <Breadcrumb
              crumbLabel={`${memberFirstName} ${memberSurname}`}
              crumbs={crumbs}
              crumbSeparator=' > '
            />
          </div>
        </div>
        <div className={sharedStyles.verticalContainer}>
          <div className={composeClasses(BlogStyles.verticalContainerInner, MemberStyles.verticalBlogInner)}>
            <div className={MemberStyles.detailsFlexContainer}>
              <div className={composeClasses(MemberStyles.leftCardContainer, MemberStyles.detailsFlexContainerInner)}>
                <MemberSVG
                  gender={memberGender}
                  hasBackground />
                <h1>{`${memberFirstName} ${memberSurname}`}</h1>
                <div>
                  <p>{memberJobTitle}</p>
                </div>
                <div className={MemberStyles.memberCityCountry}>
                  <img
                    alt='location icon'
                    src={LocationIcon} />
                  <span>{`${memberCity} - ${memberCountry}`}</span>
                </div>
                {
                  numberOfOccurence >= 1 && <div className={MemberStyles.blogSection}>
                    <h4>My Deimos Blog Posts</h4>
                    <ul>
                      {
                        memberPost.map((item, index) => {
                          if (item.node.author.node.name.includes(`${memberFirstName} ${memberSurname}`)) {
                            return (
                              <li key={index.toString()}>
                                <a
                                  href={`/post/${item.node.slug}`}
                                  target='_blank'>
                                  {item.node.title}
                                </a>
                              </li>
                            )
                          }
                        })
                      }
                    </ul>
                  </div>
                }
                <div className={MemberStyles.socialContainer}>
                  {
                    memberLinkedinUrl || memberGithubUrl || memberTwitterUrl > 0
                      ? <p>Check me out on:</p>
                      : ''
                  }
                  <div className={MemberStyles.socialLinks}>
                    {!isMobile && socialMediaDisplay()}
                  </div>
                </div>
                <button
                  className={MemberStyles.shareContainer}
                  onClick={() => openModal()}>
                  <img
                    alt='share icon'
                    src={shareIcon} />
                  <p>Share Profile</p>
                </button>
              </div>
              <div className={composeClasses(MemberStyles.detailsFlexContainerInner, MemberStyles.detailsFlexContainerInnerTwo)}>
                <div className={MemberStyles.memberDescriptionDesktop}>
                  <h3>About Me</h3>
                  <div
                    className={MemberStyles.memberDescription}
                    dangerouslySetInnerHTML={{ __html: memberDescription }}
                  />
                </div>
                {/* Mobile */}

                <div className={MemberStyles.memberDescriptionMobile}>
                  <Accordion
                    content={parse(memberDescription)}
                    contentTitle={'About Me'}
                    imageId={0}
                    memberPage
                    numbers=''
                  />
                </div>

                {memberCoreEngineeringSkills && <div>
                  <div className={MemberStyles.skillsSectionDesktop}>
                    <h3>Skills</h3>
                    {/* DESKTOP */}
                    <div className={MemberStyles.skillsSection}>
                      <div className={MemberStyles.skillsSectionInner}>
                        {memberCoreEngineeringSkills && <h4>Core Engineering Skills</h4>}
                        {
                          memberCoreEngineeringSkills && <ul>
                            {
                              memberCoreEngineeringSkills && memberCoreEngineeringSkills.map((skills, index) => {
                                for (let [key, value] of Object.entries(skills)) {
                                  key = index
                                  return (
                                    <li key={key.toString()}>{value}</li>
                                  )
                                }
                              })
                            }
                          </ul>
                        }
                      </div>
                      <div>
                        {memberSpecialisations && <h4>Specialisations</h4>}

                        {memberSpecialisations && <ul>
                          {
                            memberSpecialisations && memberSpecialisations.map((specialisations, index) => {
                              for (let [key, value] of Object.entries(specialisations)) {
                                key = index
                                return (
                                  <li key={key.toString()}>{value}</li>
                                )
                              }
                            })
                          }
                        </ul>
                        }
                      </div>
                    </div>
                  </div>

                  {/* MOBILE */}
                  <div className={MemberStyles.skillsSectionMobile}>
                    <h3>Skills</h3>
                    <div>
                      {memberCoreEngineeringSkills &&
                        <Accordion
                          content={memberCoreEngineeringSkills && <ul>
                            {
                              memberCoreEngineeringSkills && memberCoreEngineeringSkills.map((skills, index) => {
                                for (let [key, value] of Object.entries(skills)) {
                                  key = index
                                  return (
                                    <li key={key.toString()}>{value}</li>
                                  )
                                }
                              })
                            }
                          </ul>
                          }
                          contentTitle={'Core Engineering Skills'}
                          imageId={0}
                          memberPage
                          numbers=''
                        />
                      }
                    </div>
                    <div>
                      {memberSpecialisations &&
                        <Accordion
                          content={memberSpecialisations && <ul>
                            {
                              memberSpecialisations && memberSpecialisations.map((specialisations, index) => {
                                for (let [key, value] of Object.entries(specialisations)) {
                                  key = index
                                  return (
                                    <li key={key.toString()}>{value}</li>
                                  )
                                }
                              })
                            }
                          </ul>
                          }
                          contentTitle={'Specialisations'}
                          imageId={0}
                          memberPage
                          numbers=''
                        />
                      }
                    </div>
                  </div>
                </div>
                }
                <div>
                  {
                    (gcpCertification && gcpCertification) || (microsoftCertification && microsoftCertification) || (linuxFoundationCertification && linuxFoundationCertification) || (awsCertification && awsCertification) || (memberCertification && memberCertification) > 0 ? <div className={MemberStyles.certificationMain}>
                      <h3>Certifications</h3>
                      <div >
                        {
                          gcpCertification && <Accordion
                            certification
                            content={gcpCertification &&

                              gcpCertification && gcpCertification.map((item, index) => {
                              return (
                                <div
                                  className={MemberStyles.certificationSection}
                                  key={index.toString()}
                                >
                                  {
                                    item.gcpCertificationBadge && <img
                                      alt='certification badge'
                                      src={item.gcpCertificationBadge.sourceUrl} />
                                  }
                                  {
                                    item.gcpCertificationTitle && <p>{item.gcpCertificationTitle}</p>
                                  }
                                </div>
                              )
                            })
                            }
                            contentTitle={'GCP'}
                            imageId={0}
                            memberPage
                            numbers={gcpCertification && gcpCertification.length.toString()}
                          />
                        }
                        {
                          microsoftCertification && <Accordion
                            certification
                            content={microsoftCertification &&

                              microsoftCertification && microsoftCertification.map((item, index) => {
                              return (
                                <div
                                  className={MemberStyles.certificationSection}
                                  key={index.toString()}
                                >
                                  {
                                    item.microsoftCertificationBadge && <img
                                      alt='certification badge'
                                      src={item.microsoftCertificationBadge.sourceUrl} />
                                  }
                                  {
                                    item.microsoftCertificationTitle && <p>{item.microsoftCertificationTitle}</p>
                                  }
                                </div>
                              )
                            })
                            }
                            contentTitle={'Microsoft'}
                            imageId={0}
                            memberPage
                            numbers={microsoftCertification && microsoftCertification.length.toString()}
                          />
                        }
                        {
                          linuxFoundationCertification && <Accordion
                            certification
                            content={linuxFoundationCertification &&

                              linuxFoundationCertification && linuxFoundationCertification.map((item, index) => {
                              return (
                                <div
                                  className={MemberStyles.certificationSection}
                                  key={index.toString()}
                                >
                                  {
                                    item.linuxFoundationCertificationBadge && <img
                                      alt='certification badge'
                                      src={item.linuxFoundationCertificationBadge.sourceUrl} />
                                  }
                                  {
                                    item.linuxFoundationCertificationTitle && <p>{item.linuxFoundationCertificationTitle}</p>
                                  }
                                </div>
                              )
                            })
                            }
                            contentTitle={'Linux Foundation'}
                            imageId={0}
                            memberPage
                            numbers={linuxFoundationCertification && linuxFoundationCertification.length.toString()}
                          />
                        }
                        {
                          awsCertification && <Accordion
                            certification
                            content={awsCertification &&
                              awsCertification && awsCertification.map((item, index) => {
                              return (
                                <div
                                  className={MemberStyles.certificationSection}
                                  key={index.toString()}
                                >
                                  {
                                    item.awsCertificationBadge && <img
                                      alt='certification badge'
                                      src={item.awsCertificationBadge.sourceUrl} />
                                  }
                                  {
                                    item.awsCertificationTitle && <p>{item.awsCertificationTitle}</p>
                                  }
                                </div>
                              )
                            })
                            }
                            contentTitle={'AWS'}
                            imageId={0}
                            memberPage
                            numbers={awsCertification && awsCertification.length.toString()}
                          />
                        }
                        {
                          memberCertification && <Accordion
                            certification
                            content={
                              memberCertification && memberCertification.map((item, index) => {
                                return (
                                  <div
                                    className={MemberStyles.certificationSection}
                                    key={index.toString()}
                                  >
                                    {
                                      item.certificationBadge && <img
                                        alt='certification badge'
                                        src={item.certificationBadge.sourceUrl} />
                                    }
                                    {
                                      item.certificationTitle && <p>{item.certificationTitle}</p>
                                    }
                                  </div>
                                )
                              })
                            }
                            contentTitle={'Certifications'}
                            imageId={0}
                            memberPage
                            numbers={memberCertification && memberCertification.length.toString()}
                          />
                        }
                      </div>

                    </div> : ' '
                  }
                </div>

                {memberAffiliations && <div className={MemberStyles.affliationSection}>
                  <h3>Affiliations</h3>
                  {memberAffiliations && <ul>
                    {
                      memberAffiliations && memberAffiliations.map((affiliations, index) => {
                        for (let [key, value] of Object.entries(affiliations)) {
                          key = index
                          return (
                            <li key={key.toString()}>{value}</li>
                          )
                        }
                      })
                    }
                  </ul>
                  }
                </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div
          className={MemberStyles.modalContainer}
          id='shareModal'>
          <div className={MemberStyles.modalContainerInner}>
            <ShareLarge
              pageName='profile'
              url={url} />
          </div>
        </div>
      </div>
    </>
  )
}

Members.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default Members
