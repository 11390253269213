// extracted by mini-css-extract-plugin
export var verticalContainerInner = "blogDetail-module--verticalContainerInner--uUjEp";
export var detailContainer = "blogDetail-module--detailContainer--wCFAt";
export var socialContainer = "blogDetail-module--socialContainer--ZI-hM";
export var postContent = "blogDetail-module--postContent--sFEk0";
export var postDetailText = "blogDetail-module--postDetailText--anpKS";
export var postDetailAuthorAvatar = "blogDetail-module--postDetailAuthorAvatar--265Kn";
export var postImage = "blogDetail-module--postImage--zScXP";
export var postDetailAuthor = "blogDetail-module--postDetailAuthor--ohvBo";
export var postBody = "blogDetail-module--postBody--v8PO1";
export var breadCrumbsSection = "blogDetail-module--breadCrumbsSection--wuLAx";
export var postExcerpt = "blogDetail-module--postExcerpt--glIe9";
export var postDate = "blogDetail-module--postDate--5FCrT";
export var gainsWrapper = "blogDetail-module--gainsWrapper--05+fY";
export var gainsContainer = "blogDetail-module--gainsContainer--2nTrP";
export var gainsTitle = "blogDetail-module--gainsTitle--yjK5H";
export var gainsList = "blogDetail-module--gainsList--sUkK2";
export var gainsLink = "blogDetail-module--gainsLink--vH2w6";
export var blogBackground = "blogDetail-module--blogBackground--kLjbA";
export var authorName = "blogDetail-module--authorName--vs6fw";